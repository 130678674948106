import React from 'react'

import Layout from '../components/layout'
import ServicesOne from '../components/services/ServicesOne'
import ServicesThree from '../components/services/ServicesThree'
import ServicesTwo from '../components/services/ServicesTwo'

const Services = props => (
  <Layout lang="en" title="Services" meta={{description: 'A global tech partner at your service. From strategy to digital products and integrations.'}} rootProps={{ className: 'page services' }}>
    <ServicesOne lang='en' />
    <ServicesTwo lang='en' />
    <ServicesThree lang='en' />
  </Layout>
)

export default Services
